import { gql, useMutation } from '@apollo/client';
import { graphql, navigate } from 'gatsby';
import * as React from 'react';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../components/common/breadcrumbs/Breadcrumbs';
import Callout from '../components/common/callout/Callout';
import Layout from '../components/layout/Layout';
import SideBar from '../components/layout/SideBar';
import { ERROR_ENTITY, getPageLink, GQL_SUBSCRIBE } from '../constants';
import {
  SET_CONFIRMATION_NUMBER,
  SET_LOADING_OFF,
  SET_LOADING_ON,
} from '../context/actions/ActionTypes';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { errorHandler, showUnexpectedError } from '../errorHandler';
import './PaymentPage.scss';
import { PageContentType } from '../context/PageContentType';

/**
 * Payment Page
 *
 * @param props
 * @constructor
 */
const PaymentPage: React.FunctionComponent<PageContentType> = props => {
  const { t } = useTranslation();

  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);

  const bc = [
    { label: 'home' },
    { label: 'chevron' },
    {
      label: t('breadcrumbs.payment'),
      path: getPageLink(props.pageContext.lang, 'PAYMENT_PAGE'),
    },
  ];

  const [
    productSubscribe,
    {
      loading: productSubscribeLoading,
      data: productSubscribeData,
      error: productSubscribeError,
    },
  ] = useMutation(
    gql`
      ${GQL_SUBSCRIBE}
    `,
    {
      errorPolicy: 'all',
    },
  );

  useEffect(() => {
    if (productSubscribeLoading) {
      dispatch({
        type: SET_LOADING_ON,
      });
      return;
    } else {
      dispatch({
        type: SET_LOADING_OFF,
      });
    }

    if (productSubscribeError) {
      errorHandler(
        dispatch,
        t,
        productSubscribeError,
        ERROR_ENTITY.PAYMENT_SUBSCRIPTION,
        props.pageContext.lang,
      );

      return;
    }

    if (productSubscribeData) {
      if (productSubscribeData?.subscribe) {
        dispatch({
          type: SET_CONFIRMATION_NUMBER,
          confirmationNumber: productSubscribeData.subscribe[0].id,
        });

        navigate(getPageLink(props.pageContext.lang, 'CONFIRMATION_PAGE'));

        return;
      }

      // Handling unexpected error (should never happen)
      showUnexpectedError(dispatch);

      return;
    }
  }, [productSubscribeLoading, productSubscribeData, productSubscribeError]);

  return (
    <Layout
      lang={props.pageContext.lang}
      currentLangUrl={props.pageContext.currentLangUrl}
      otherLangUrl={props.pageContext.otherLangUrl}
      pageId={getPageLink(props.pageContext.lang, 'PAYMENT_PAGE')}
      pageTitle={t('paymentPage.title')}
    >
      <div className="ontario-row">
        <div className="ontario-columns ontario-small-12 ontario-medium-9">
          <Breadcrumbs bc={bc} lang={props.pageContext.lang} />

          <h1>{t('paymentPage.title')}</h1>

          <p>
            <strong>{t('paymentPage.desc01')}</strong>
          </p>

          <Callout
            additionalClass={'mdmp__order-callout mdmp__back'}
            lang={props.pageContext.lang}
            title={t('paymentPage.order')}
          >
            <p>
              {state.userProfile.cart?.[0]?.name} {t('paymentPage.data')}
            </p>
            <div className={'mdmp__price'}>
              {/*<div>{t('paymentPage.purchasePrice')}</div>*/}
              {/*<div>$TBD</div>*/}
              <div>{t('paymentPage.chargedPerCall')}</div>
            </div>
          </Callout>

          <div className={'mdmp__order-buttons'}>
            <button
              className="ontario-button ontario-button--secondary"
              onClick={() =>
                navigate(
                  getPageLink(
                    props.pageContext.lang,
                    'PRODUCT',
                    'PRODUCT_DETAILS',
                  ) + '/aris',
                )
              }
            >
              {t('buttons.noCancel')}
            </button>

            <button
              className="ontario-button ontario-button--primary"
              onClick={() => productSubscribe()}
            >
              {t('buttons.yesConfirm')}
            </button>
          </div>
        </div>

        <SideBar />
      </div>
    </Layout>
  );
};

export default PaymentPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
